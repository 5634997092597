<template>
  <div class="login-container">
    <div class="buttxt">Голосования 
      <el-switch style="margin-left:5%;" @change="selElects" v-if="isAdm()"
        v-model="actual"
        active-text="Актуальные"
        inactive-text="Прошедшие">
      </el-switch>      
      <el-select class="login-form" v-model="el_id" @change="set_elect0" autocomplete placeholder="Выбор голосования" no-match-text="Ничего нет">
        <el-option
          v-for="item in elects"
          :key="item.id"
          :label="item.name + ' ' + formdt(item.bedt) + '-' + formdt(item.endt)"
          :value="item.id"
        >
        <span class="seltxt">{{item.name + ' ' + formdt(item.bedt) + '-' + formdt(item.endt)}} </span>
        </el-option>
      </el-select>
    </div>
    <div class="login-form" v-if="el_id">
      <el-collapse accordion @change="selItem" v-model="name_collap">
        <el-collapse-item name="proto">
          <template #title><span class="buttxt">Информация</span></template>
          <div ref="text" v-html="proto"></div>
        </el-collapse-item>
        <el-collapse-item name="elect">
          <template #title><span class="buttxt">Голосование</span></template>
          <Surveycom :id=el_id :userid=user_id :elres=user_el_res :isClosed=isClosed @complete="complete"/>
        </el-collapse-item>
        <el-collapse-item name="result">
          <template #title><span class="buttxt">Результаты</span></template>
          <p class="buttxt">Проголосовали: {{kl_result}} из {{kl_elector}} ( {{(100*kl_result/kl_elector).toFixed(2)}}% ) </p>
          <ul class="buttxt">
            <li v-for="it in el_itog" :key="it.key">
              {{it.key}}
              <ul>
                <li v-for="v in it.arr" :key="v.value">
                  {{v.value+': голосов '+v.kl+' ('+ (100*v.kl/kl_result).toFixed(2) +'%)'}}
                </li>
              </ul>
            </li>
          </ul>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="buttxt" v-if="isAdmin()">Голосовать от имени 
    <el-select class="login-form" v-model="user_id" @change="name_collap=''" filterable autocomplete placeholder="Выбор участника" no-match-text="Ничего нет">
      <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.username"
      :value="item.id"
      >
      <span class="buttxt">{{item.username}} </span>
      </el-option>
    </el-select>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Surveycom from '../components/Surveycom'
import { hostus } from "../utils/func.js";

//const hostus = (process.env.NODE_ENV == "development" ? 'http://localhost:3000' : '')

export default {
  components: { Surveycom },
  data() {
    return {
      //actual: true,
      isAdm: () => this.$store.getters['isAdm'],
      elects: [],
      name_collap: '',
      name: '',
      user_id: null,
      user_el_res: {},
      options: [],
      el_id: null,
      el_bedt: null,
      el_endt: null,
      kl_elector: 0,
      kl_result: 0,
      proto: null,
      result: [],
      el_itog: [],
      json: {} 
    }
  },
  created() {
    this.selElects()
  },
  mounted: async function () { 
    //this.$store.commit('user/set_elect_id', this.el_id)
    this.user_id = (this.$store.getters['user_da']).id
    let result = await axios.post(`${hostus}/mysqlus/select`, { username: '*', where: 'dis = 0' })
    this.options = result.data.sort((a,b) => a.username > b.username ? 1 : -1)
    this.kl_elector = (result.data).length; //console.log('this.kl_elector=', this.kl_elector)
    this.$store.commit('user/set_kl_elector', this.kl_elector)
    /* let res = await axios.post(`${hostus}/mysqlus/sel_result`, { el_id: this.el_id })
    this.result = res.data.rows
    this.kl_result = res.data.kl
    console.log('this.result=', this.result)
    this.itog(res.data.rows) */
    //this.get_result()
    if (this.$store.getters['debug']) 
      console.log('process.env.BASE_URL=',process.env.BASE_URL,this.$store.getters['debug'])

  },
  computed: {
    isClosed() { return this.el_closed() },
    actual: { // переключатель текущих/прошедших голосований
      get: function () { return this.$store.getters['actual'] },
      set: function (newVal) {
        this.$store.commit('user/set_curElect', null) 
        this.$store.commit('user/set_actual', newVal) 
      }
    },
  },
  methods: {
    complete (res) { 
      let id = this.user_id //(this.$store.getters['user_da']).id 
      let u = this.options.find((item) => item.id == id)
      //console.log('u=',u)
      axios.put(`${hostus}/mysqlus/upd_result`, {el_id: this.el_id, el_pass: `ei${this.el_id}-ui${id}`, el_res: res, username: `${u.username} ${u.boxes}`})
        .then(response => {
          if (response.data.errmsg) { this.$alert(response.data.errmsg, 'Ошибка', {type: 'error'})}
          if (response.data.okmsg) { 
            let klneot = this.$store.getters['curElectQuestions'].length - Object.keys(res).length
            const sdop = klneot > 0 ? `<p style="color:red"> Осталось без ответа: ${klneot}</p>` : ``
            let stext = `<strong>Ваше мнение учтено.</strong>` + sdop
            this.$alert(stext, response.data.okmsg, {type: 'success', dangerouslyUseHTMLString: true})
            //console.log('res=',res)
            this.get_result()
            this.name_collap = ''
          }
        })
        .catch(error => this.$alert(error.message, 'Ошибка', {type: 'error', confirmButtonText: 'Закрыть', showClose: false}))
    },
    selItem (name) { // обработка события выбора строки в collapse
      if (name == 'elect') {
        let elms = this.$store.getters['curElectQuestions']; //console.log('els=',elms)
        this.user_el_res = {}
        let id = this.user_id //(this.$store.getters['user_da']).id 
        axios.post(`${hostus}/mysqlus/sel_result`, { el_id: this.el_id, el_pass: `ei${this.el_id}-ui${id}` })
          .then(response => {
            //console.log('response.data=', response.data)
            if (response.data.el_res) {
              let title = ( this.el_closed() ? 'Голосование закрыто' : `Возможно внести изменение по ${this.formdt(this.el_endt)} `)
              let itog = '<strong>Уже сделанный Вами выбор: </strong>'
              let elres = response.data.el_res
              this.user_el_res = Object.assign({}, elres)
              elms.forEach(element => {
                //if (element.choices.length > 0) {
                  let a = elres[element.name] ? elres[element.name] : "<span style='color:red'>Нет ответа</span>"
                  itog += `<p>${element.name}: <strong>${a}</strong></p>`
                //}
              })
              //for (const key in elres) { itog += `<p>${key}: ${elres[key]} </p>`}
              this.$alert(itog, title, {type: 'success', confirmButtonText: 'Закрыть', showClose: false, dangerouslyUseHTMLString: true})
            }
          })
      }
    },
    isAdmin () { return this.$store.getters['isAdmin'] },
    selElects () {
      this.name_collap = ''
      let prm = (this.actual ? {} : {notactual: 'NOT'})
      axios.post(`${hostus}/mysqlus/sel_elect`, prm)
        .then(response => { 
          const { data } = response; 
          if (data.errmsg) { this.$alert(data.errmsg, 'Ошибка', {})}
          else {
            this.elects = data.slice().sort((a, b) => {let x = (a.bedt < b.bedt ? 1 : -1); return x})
            if (this.elects.length > 0) {
              this.el_id = this.$store.getters['elect_id']
              if (this.el_id) this.set_elect()
              else this.set_elect(0)
            }
          }
      })
    },
    set_elect0 (elid) { //console.log('elid=',elid)
      elid = elid || this.el_id
      this.name_collap = ''
      let ind = this.elects.findIndex(item => item.id == elid)
      this.set_elect(ind)
    },
    set_elect (index) {
      if (index == undefined) index = this.elects.findIndex(item => item.id == this.el_id)
      let data0 = this.elects[index]
      this.$store.commit('user/set_curElect', data0)
      this.name = data0.name
      this.el_id = data0.id
      this.el_bedt = data0.bedt
      this.el_endt = data0.endt
      this.get_proto()
      this.get_result()
      //console.log('this.$store.getters[elect_id]=',this.$store.getters['elect_id'])
    },
    async get_result() {
      let res = await axios.post(`${hostus}/mysqlus/sel_result`, { el_id: this.el_id })
      this.result = res.data.rows
      this.$store.commit('user/set_curResult', res.data.rows)
      this.kl_result = res.data.kl
      //console.log('this.result=', this.result)
      this.itog(res.data.rows)
    },
    async get_proto() {
      let res = await axios.get(`${hostus}/mysqlus/proto`, {params: {file: `proto-${this.el_id}` }} )
      let proto = res.data
      if (proto.errmsg) this.proto = " Не найден протокол" //this.$refs.text.innerHTML = '<el-empty description="Не найден протокол"></el-empty>' 
      else this.proto = proto
    },
    itog (res) {
      let itog = []
      res.forEach(item => { 
        let elres = item.el_res
        for (const key in elres) { //console.log('itog=', itog)
          if (Object.hasOwnProperty.call(elres, key)) {
            let itogcur = itog.find(itogel => itogel.key == key)
            if (itogcur) {
              const arrel = itogcur.arr
              let cur = arrel.find((itarr => itarr.value == elres[key]))
              if (cur) cur.kl += 1
              else arrel.push({ value: elres[key], kl: 1})
            }
            else {
              itog.push({key: key, arr: [{ value: elres[key], kl: 1}]})
            }
          }
        }
      })
      this.el_itog = [].concat(itog)
      this.el_itog.sort((a, b) => {let x = (a.key > b.key ? 1 : -1); return x})
      if (this.$store.getters['debug']) console.log('this.el_itog=',this.el_itog)
    },
    el_closed() { // голосование закрыто
      let date = new Date(); 
      let bedate = new Date(this.el_bedt)
      let endate = new Date(this.el_endt)
      //console.log('this.el_endt=',this.el_endt,date,endate)
      return date < bedate || date > endate
    },
    formdt(date) { let dt = new Date(date); return `${dt.getDate()}.${dt.getMonth()+1}.${dt.getFullYear()}`}
  },
}
</script>

<style>
.login-container {
    position: relative;
    width: 400px;
    max-width: 100%;
    padding: 10px 0px 10px;
    margin: 0 auto;
  background-color: rgb(215, 232, 240);
  overflow: hidden;
}
.login-form {
    position: relative;
    width: 350px;
    max-width: 100%;
    padding: 10px 15px 15px;
    margin: 0 auto;
    overflow: hidden;
}
.buttxt {
  font-size: 1rem;
  margin-left:2%;
}
.seltxt {
  font-size: 1em;
  margin-left:1%;
}

</style>
